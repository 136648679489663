export const companyTransactionsConstants = {
  FETCH_ALL_COMPANY_TRANSACTIONS_REQUEST: 'FETCH_ALL_COMPANY_TRANSACTIONS_REQUEST',
  FETCH_ALL_COMPANY_TRANSACTIONS_SUCCESS: 'FETCH_ALL_COMPANY_TRANSACTIONS_SUCCESS',
  FETCH_ALL_COMPANY_TRANSACTIONS_FAILURE: 'FETCH_ALL_COMPANY_TRANSACTIONS_FAILURE',

  FETCH_COMPANY_TRANSACTION_REQUEST: 'FETCH_COMPANY_TRANSACTION_REQUEST',
  FETCH_COMPANY_TRANSACTION_SUCCESS: 'FETCH_COMPANY_TRANSACTION_SUCCESS',
  FETCH_COMPANY_TRANSACTION_FAILURE: 'FETCH_COMPANY_TRANSACTION_FAILURE',

  FETCH_COMPANY_CASHFLOW_REQUEST: 'FETCH_COMPANY_CASHFLOW_REQUEST',
  FETCH_COMPANY_CASHFLOW_SUCCESS: 'FETCH_COMPANY_CASHFLOW_SUCCESS',
  FETCH_COMPANY_CASHFLOW_FAILURE: 'FETCH_COMPANY_CASHFLOW_FAILURE',

  CREATE_COMPANY_TRANSACTION_REQUEST: 'CREATE_COMPANY_TRANSACTION_REQUEST',
  CREATE_COMPANY_TRANSACTION_SUCCESS: 'CREATE_COMPANY_TRANSACTION_SUCCESS',
  CREATE_COMPANY_TRANSACTION_FAILURE: 'CREATE_COMPANY_TRANSACTION_FAILURE',

  CREATE_COMPANY_TRANSACTION_RECIPIENT_REQUEST: 'CREATE_COMPANY_TRANSACTION_RECIPIENT_REQUEST',
  CREATE_COMPANY_TRANSACTION_RECIPIENT_SUCCESS: 'CREATE_COMPANY_TRANSACTION_RECIPIENT_SUCCESS',
  CREATE_COMPANY_TRANSACTION_RECIPIENT_FAILURE: 'CREATE_COMPANY_TRANSACTION_RECIPIENT_FAILURE',

  FETCH_ALL_RECIPIENTS_REQUEST: 'FETCH_ALL_RECIPIENTS_REQUEST',
  FETCH_ALL_RECIPIENTS_SUCCESS: 'FETCH_ALL_RECIPIENTS_SUCCESS',
  FETCH_ALL_RECIPIENTS_FAILURE: 'FETCH_ALL_RECIPIENTS_FAILURE',

  FETCH_ALL_RECIPIENTS_SIMPLE_REQUEST: 'FETCH_ALL_RECIPIENTS_SIMPLE_REQUEST',
  FETCH_ALL_RECIPIENTS_SIMPLE_SUCCESS: 'FETCH_ALL_RECIPIENTS_SIMPLE_SUCCESS',
  FETCH_ALL_RECIPIENTS_SIMPLE_FAILURE: 'FETCH_ALL_RECIPIENTS_SIMPLE_FAILURE',

  FETCH_PAGINATED_RECIPIENTS_REQUEST: 'FETCH_PAGINATED_RECIPIENTS_REQUEST',
  FETCH_PAGINATED_RECIPIENTS_SUCCESS: 'FETCH_PAGINATED_RECIPIENTS_SUCCESS',
  FETCH_PAGINATED_RECIPIENTS_FAILURE: 'FETCH_PAGINATED_RECIPIENTS_FAILURE',

  FETCH_CONTACTS_SUMMARY_REQUEST: 'FETCH_CONTACTS_SUMMARY_REQUEST',
  FETCH_CONTACTS_SUMMARY_SUCCESS: 'FETCH_CONTACTS_SUMMARY_SUCCESS',
  FETCH_CONTACTS_SUMMARY_FAILURE: 'FETCH_CONTACTS_SUMMARY_FAILURE',

  // CATEGORIES

  FETCH_ALL_CATEGORIES_REQUEST: 'FETCH_ALL_CATEGORIES_REQUEST',
  FETCH_ALL_CATEGORIES_SUCCESS: 'FETCH_ALL_CATEGORIES_SUCCESS',
  FETCH_ALL_CATEGORIES_FAILURE: 'FETCH_ALL_CATEGORIES_FAILURE',

  CREATE_COMPANY_TRANSACTION_CATEGORY_REQUEST: 'CREATE_COMPANY_TRANSACTION_CATEGORY_REQUEST',
  CREATE_COMPANY_TRANSACTION_CATEGORY_SUCCESS: 'CREATE_COMPANY_TRANSACTION_CATEGORY_SUCCESS',
  CREATE_COMPANY_TRANSACTION_CATEGORY_FAILURE: 'CREATE_COMPANY_TRANSACTION_CATEGORY_FAILURE',

  UPDATE_TRANSACTION_CATEGORY_REQUEST: 'UPDATE_TRANSACTION_CATEGORY_REQUEST',
  UPDATE_TRANSACTION_CATEGORY_SUCCESS: 'UPDATE_TRANSACTION_CATEGORY_SUCCESS',
  UPDATE_TRANSACTION_CATEGORY_FAILURE: 'UPDATE_TRANSACTION_CATEGORY_FAILURE',

  DELETE_TRANSACTION_CATEGORY_REQUEST: 'DELETE_TRANSACTION_CATEGORY_REQUEST',
  DELETE_TRANSACTION_CATEGORY_SUCCESS: 'DELETE_TRANSACTION_CATEGORY_SUCCESS',
  DELETE_TRANSACTION_CATEGORY_FAILURE: 'DELETE_TRANSACTION_CATEGORY_FAILURE',

  // END CATEGORIES

  // COST_CENTER

  FETCH_ALL_COMPANY_TRANSACTION_COSTS_CENTER_REQUEST: 'FETCH_ALL_COMPANY_TRANSACTION_COSTS_CENTER_REQUEST',
  FETCH_ALL_COMPANY_TRANSACTION_COSTS_CENTER_SUCCESS: 'FETCH_ALL_COMPANY_TRANSACTION_COSTS_CENTER_SUCCESS',
  FETCH_ALL_COMPANY_TRANSACTION_COSTS_CENTER_FAILURE: 'FETCH_ALL_COMPANY_TRANSACTION_COSTS_CENTER_FAILURE',

  CREATE_COMPANY_TRANSACTION_COST_CENTER_REQUEST: 'CREATE_COMPANY_TRANSACTION_COST_CENTER_REQUEST',
  CREATE_COMPANY_TRANSACTION_COST_CENTER_SUCCESS: 'CREATE_COMPANY_TRANSACTION_COST_CENTER_SUCCESS',
  CREATE_COMPANY_TRANSACTION_COST_CENTER_FAILURE: 'CREATE_COMPANY_TRANSACTION_COST_CENTER_FAILURE',

  UPDATE_TRANSACTION_COST_CENTER_REQUEST: 'UPDATE_TRANSACTION_COST_CENTER_REQUEST',
  UPDATE_TRANSACTION_COST_CENTER_SUCCESS: 'UPDATE_TRANSACTION_COST_CENTER_SUCCESS',
  UPDATE_TRANSACTION_COST_CENTER_FAILURE: 'UPDATE_TRANSACTION_COST_CENTER_FAILURE',

  DELETE_TRANSACTION_COST_CENTER_REQUEST: 'DELETE_TRANSACTION_COST_CENTER_REQUEST',
  DELETE_TRANSACTION_COST_CENTER_SUCCESS: 'DELETE_TRANSACTION_COST_CENTER_SUCCESS',
  DELETE_TRANSACTION_COST_CENTER_FAILURE: 'DELETE_TRANSACTION_COST_CENTER_FAILURE',

  // END COST_CENTER

  // TAGS

  FETCH_ALL_COMPANY_TRANSACTION_TAGS_REQUEST: 'FETCH_ALL_COMPANY_TRANSACTION_TAGS_REQUEST',
  FETCH_ALL_COMPANY_TRANSACTION_TAGS_SUCCESS: 'FETCH_ALL_COMPANY_TRANSACTION_TAGS_SUCCESS',
  FETCH_ALL_COMPANY_TRANSACTION_TAGS_FAILURE: 'FETCH_ALL_COMPANY_TRANSACTION_TAGS_FAILURE',

  CREATE_COMPANY_TRANSACTION_TAGS_REQUEST: 'CREATE_COMPANY_TRANSACTION_TAGS_REQUEST',
  CREATE_COMPANY_TRANSACTION_TAGS_SUCCESS: 'CREATE_COMPANY_TRANSACTION_TAGS_SUCCESS',
  CREATE_COMPANY_TRANSACTION_TAGS_FAILURE: 'CREATE_COMPANY_TRANSACTION_TAGS_FAILURE',

  UPDATE_TRANSACTION_TAGS_REQUEST: 'UPDATE_TRANSACTION_TAGS_REQUEST',
  UPDATE_TRANSACTION_TAGS_SUCCESS: 'UPDATE_TRANSACTION_TAGS_SUCCESS',
  UPDATE_TRANSACTION_TAGS_FAILURE: 'UPDATE_TRANSACTION_TAGS_FAILURE',

  DELETE_TRANSACTION_TAGS_REQUEST: 'DELETE_TRANSACTION_TAGS_REQUEST',
  DELETE_TRANSACTION_TAGS_SUCCESS: 'DELETE_TRANSACTION_TAGS_SUCCESS',
  DELETE_TRANSACTION_TAGS_FAILURE: 'DELETE_TRANSACTION_TAGS_FAILURE',

  APPLY_TAGS_TO_TRANSACTION_REQUEST: 'APPLY_TAGS_TO_TRANSACTION_REQUEST',
  APPLY_TAGS_TO_TRANSACTION_SUCCESS: 'APPLY_TAGS_TO_TRANSACTION_SUCCESS',
  APPLY_TAGS_TO_TRANSACTION_FAILURE: 'APPLY_TAGS_TO_TRANSACTION_FAILURE',

  // END TAGS

  UPDATE_COMPANY_TRANSACTION_REQUEST: 'UPDATE_COMPANY_TRANSACTION_REQUEST',
  UPDATE_COMPANY_TRANSACTION_SUCCESS: 'UPDATE_COMPANY_TRANSACTION_SUCCESS',
  UPDATE_COMPANY_TRANSACTION_FAILURE: 'UPDATE_COMPANY_TRANSACTION_FAILURE',

  DELETE_COMPANY_TRANSACTION_REQUEST: 'DELETE_COMPANY_TRANSACTION_REQUEST',
  DELETE_COMPANY_TRANSACTION_SUCCESS: 'DELETE_COMPANY_TRANSACTION_SUCCESS',
  DELETE_COMPANY_TRANSACTION_FAILURE: 'DELETE_COMPANY_TRANSACTION_FAILURE',

  DELETE_MULTIPLE_COMPANY_TRANSACTION_REQUEST: 'DELETE_MULTIPLE_COMPANY_TRANSACTION_REQUEST',
  DELETE_MULTIPLE_COMPANY_TRANSACTION_SUCCESS: 'DELETE_MULTIPLE_COMPANY_TRANSACTION_SUCCESS',
  DELETE_MULTIPLE_COMPANY_TRANSACTION_FAILURE: 'DELETE_MULTIPLE_COMPANY_TRANSACTION_FAILURE',

  TOGGLE_COMPANY_TRANSACTION_PAID_REQUEST: 'TOGGLE_COMPANY_TRANSACTION_PAID_REQUEST',
  TOGGLE_COMPANY_TRANSACTION_PAID_SUCCESS: 'TOGGLE_COMPANY_TRANSACTION_PAID_SUCCESS',
  TOGGLE_COMPANY_TRANSACTION_PAID_FAILURE: 'TOGGLE_COMPANY_TRANSACTION_PAID_FAILURE',

  TOGGLE_MULTIPLE_TRANSACTIONS_PAID_REQUEST: 'TOGGLE_MULTIPLE_TRANSACTIONS_PAID_REQUEST',
  TOGGLE_MULTIPLE_TRANSACTIONS_PAID_SUCCESS: 'TOGGLE_MULTIPLE_TRANSACTIONS_PAID_SUCCESS',
  TOGGLE_MULTIPLE_TRANSACTIONS_PAID_FAILURE: 'TOGGLE_MULTIPLE_TRANSACTIONS_PAID_FAILURE',

  FETCH_ALL_BANK_ACCOUNTS_REQUEST: 'FETCH_ALL_BANK_ACCOUNTS_REQUEST',
  FETCH_ALL_BANK_ACCOUNTS_SUCCESS: 'FETCH_ALL_BANK_ACCOUNTS_SUCCESS',
  FETCH_ALL_BANK_ACCOUNTS_FAILURE: 'FETCH_ALL_BANK_ACCOUNTS_FAILURE',

  GENERATE_TRANSACTION_REPORT_REQUEST: 'GENERATE_TRANSACTION_REPORT_REQUEST',
  GENERATE_TRANSACTION_REPORT_SUCCESS: 'GENERATE_TRANSACTION_REPORT_SUCCESS',
  GENERATE_TRANSACTION_REPORT_FAILURE: 'GENERATE_TRANSACTION_REPORT_FAILURE',

  UPLOAD_ZEROPAPER_SPREADSHEET_REQUEST: 'UPLOAD_ZEROPAPER_SPREADSHEET_REQUEST',
  UPLOAD_ZEROPAPER_SPREADSHEET_SUCCESS: 'UPLOAD_ZEROPAPER_SPREADSHEET_SUCCESS',
  UPLOAD_ZEROPAPER_SPREADSHEET_FAILURE: 'UPLOAD_ZEROPAPER_SPREADSHEET_FAILURE',

  FETCH_BANK_ACCOUNT_BALANCE_REQUEST: 'FETCH_BANK_ACCOUNT_BALANCE_REQUEST',
  FETCH_BANK_ACCOUNT_BALANCE_SUCCESS: 'FETCH_BANK_ACCOUNT_BALANCE_SUCCESS',
  FETCH_BANK_ACCOUNT_BALANCE_FAILURE: 'FETCH_BANK_ACCOUNT_BALANCE_FAILURE',

  GET_FINANCIAL_DATA_FOR_DELETION_REQUEST: 'GET_FINANCIAL_DATA_FOR_DELETION_REQUEST',
  GET_FINANCIAL_DATA_FOR_DELETION_SUCCESS: 'GET_FINANCIAL_DATA_FOR_DELETION_SUCCESS',
  GET_FINANCIAL_DATA_FOR_DELETION_FAILURE: 'GET_FINANCIAL_DATA_FOR_DELETION_FAILURE',

  DELETE_COMPANY_FINANCIAL_DATA_REQUEST: 'DELETE_COMPANY_FINANCIAL_DATA_REQUEST',
  DELETE_COMPANY_FINANCIAL_DATA_SUCCESS: 'DELETE_COMPANY_FINANCIAL_DATA_SUCCESS',
  DELETE_COMPANY_FINANCIAL_DATA_FAILURE: 'DELETE_COMPANY_FINANCIAL_DATA_FAILURE',

  FETCH_TRANSACTION_ACCOUNTS_REQUEST: 'FETCH_TRANSACTION_ACCOUNTS_REQUEST',
  FETCH_TRANSACTION_ACCOUNTS_SUCCESS: 'FETCH_TRANSACTION_ACCOUNTS_SUCCESS',
  FETCH_TRANSACTION_ACCOUNTS_FAILURE: 'FETCH_TRANSACTION_ACCOUNTS_FAILURE',

  CREATE_TRANSACTION_ACCOUNT_REQUEST: 'CREATE_TRANSACTION_ACCOUNT_REQUEST',
  CREATE_TRANSACTION_ACCOUNT_SUCCESS: 'CREATE_TRANSACTION_ACCOUNT_SUCCESS',
  CREATE_TRANSACTION_ACCOUNT_FAILURE: 'CREATE_TRANSACTION_ACCOUNT_FAILURE',

  UPDATE_TRANSACTION_ACCOUNT_REQUEST: 'UPDATE_TRANSACTION_ACCOUNT_REQUEST',
  UPDATE_TRANSACTION_ACCOUNT_SUCCESS: 'UPDATE_TRANSACTION_ACCOUNT_SUCCESS',
  UPDATE_TRANSACTION_ACCOUNT_FAILURE: 'UPDATE_TRANSACTION_ACCOUNT_FAILURE',

  DELETE_TRANSACTION_ACCOUNT_REQUEST: 'DELETE_TRANSACTION_ACCOUNT_REQUEST',
  DELETE_TRANSACTION_ACCOUNT_SUCCESS: 'DELETE_TRANSACTION_ACCOUNT_SUCCESS',
  DELETE_TRANSACTION_ACCOUNT_FAILURE: 'DELETE_TRANSACTION_ACCOUNT_FAILURE',

  CHANGE_TRANSACTION_RECIPIENT_REQUEST: 'CHANGE_TRANSACTION_RECIPIENT_REQUEST',
  CHANGE_TRANSACTION_RECIPIENT_SUCCESS: 'CHANGE_TRANSACTION_RECIPIENT_SUCCESS',
  CHANGE_TRANSACTION_RECIPIENT_FAILURE: 'CHANGE_TRANSACTION_RECIPIENT_FAILURE',

  DELETE_MULTIPLE_RECIPIENTS_REQUEST: 'DELETE_MULTIPLE_RECIPIENTS_REQUEST',
  DELETE_MULTIPLE_RECIPIENTS_SUCCESS: 'DELETE_MULTIPLE_RECIPIENTS_SUCCESS',
  DELETE_MULTIPLE_RECIPIENTS_FAILURE: 'DELETE_MULTIPLE_RECIPIENTS_FAILURE',

  CHANGE_TRANSACTION_CATEGORY_REQUEST: 'CHANGE_TRANSACTION_CATEGORY_REQUEST',
  CHANGE_TRANSACTION_CATEGORY_SUCCESS: 'CHANGE_TRANSACTION_CATEGORY_SUCCESS',
  CHANGE_TRANSACTION_CATEGORY_FAILURE: 'CHANGE_TRANSACTION_CATEGORY_FAILURE',

  SET_SELECTED_ACCOUNT_REQUEST: 'SET_SELECTED_ACCOUNT_REQUEST',
  SET_SELECTED_ACCOUNT_SUCCESS: 'SET_SELECTED_ACCOUNT_SUCCESS',
  SET_SELECTED_ACCOUNT_FAILURE: 'SET_SELECTED_ACCOUNT_FAILURE',

  FETCH_SEMESTER_OVERVIEW_REQUEST: 'FETCH_SEMESTER_OVERVIEW_REQUEST',
  FETCH_SEMESTER_OVERVIEW_SUCCESS: 'FETCH_SEMESTER_OVERVIEW_SUCCESS',
  FETCH_SEMESTER_OVERVIEW_FAILURE: 'FETCH_SEMESTER_OVERVIEW_FAILURE',

  SET_FAVORITE_BANK_ACCOUNT_REQUEST: 'SET_FAVORITE_BANK_ACCOUNT_REQUEST',
  SET_FAVORITE_BANK_ACCOUNT_SUCCESS: 'SET_FAVORITE_BANK_ACCOUNT_SUCCESS',
  SET_FAVORITE_BANK_ACCOUNT_FAILURE: 'SET_FAVORITE_BANK_ACCOUNT_FAILURE',

  CHANGE_TRANSACTION_TRANSFER_ORIGIN_ACCOUNT_REQUEST: 'CHANGE_TRANSACTION_TRANSFER_ORIGIN_ACCOUNT_REQUEST',
  CHANGE_TRANSACTION_TRANSFER_ORIGIN_ACCOUNT_SUCCESS: 'CHANGE_TRANSACTION_TRANSFER_ORIGIN_ACCOUNT_SUCCESS',
  CHANGE_TRANSACTION_TRANSFER_ORIGIN_ACCOUNT_FAILURE: 'CHANGE_TRANSACTION_TRANSFER_ORIGIN_ACCOUNT_FAILURE',

  CHANGE_TRANSACTION_TRANSFER_DESTINATION_ACCOUNT_REQUEST: 'CHANGE_TRANSACTION_TRANSFER_DESTINATION_ACCOUNT_REQUEST',
  CHANGE_TRANSACTION_TRANSFER_DESTINATION_ACCOUNT_SUCCESS: 'CHANGE_TRANSACTION_TRANSFER_DESTINATION_ACCOUNT_SUCCESS',
  CHANGE_TRANSACTION_TRANSFER_DESTINATION_ACCOUNT_FAILURE: 'CHANGE_TRANSACTION_TRANSFER_DESTINATION_ACCOUNT_FAILURE',

  CREATE_TRANSFER_TRANSACTION_REQUEST: 'CREATE_TRANSFER_TRANSACTION_REQUEST',
  CREATE_TRANSFER_TRANSACTION_SUCCESS: 'CREATE_TRANSFER_TRANSACTION_SUCCESS',
  CREATE_TRANSFER_TRANSACTION_FAILURE: 'CREATE_TRANSFER_TRANSACTION_FAILURE',

  DUPLICATE_TRANSACTIONS_REQUEST: 'DUPLICATE_TRANSACTIONS_REQUEST',
  DUPLICATE_TRANSACTIONS_SUCCESS: 'DUPLICATE_TRANSACTIONS_SUCCESS',
  DUPLICATE_TRANSACTIONS_FAILURE: 'DUPLICATE_TRANSACTIONS_FAILURE',

  DUPLICATE_MULTIPLE_COMPANY_TRANSACTION_REQUEST: 'DUPLICATE_MULTIPLE_COMPANY_TRANSACTION_REQUEST',
  DUPLICATE_MULTIPLE_COMPANY_TRANSACTION_SUCCESS: 'DUPLICATE_MULTIPLE_COMPANY_TRANSACTION_SUCCESS',
  DUPLICATE_MULTIPLE_COMPANY_TRANSACTION_FAILURE: 'DUPLICATE_MULTIPLE_COMPANY_TRANSACTION_FAILURE',

  UPDATE_MULTIPLE_TRANSACTIONS_REQUEST: 'UPDATE_MULTIPLE_TRANSACTIONS_REQUEST',
  UPDATE_MULTIPLE_TRANSACTIONS_SUCCESS: 'UPDATE_MULTIPLE_TRANSACTIONS_SUCCESS',
  UPDATE_MULTIPLE_TRANSACTIONS_FAILURE: 'UPDATE_MULTIPLE_TRANSACTIONS_FAILURE',

  RECONCILE_MULTIPLE_TRANSACTIONS_REQUEST: 'RECONCILE_MULTIPLE_TRANSACTIONS_REQUEST',
  RECONCILE_MULTIPLE_TRANSACTIONS_SUCCESS: 'RECONCILE_MULTIPLE_TRANSACTIONS_SUCCESS',
  RECONCILE_MULTIPLE_TRANSACTIONS_FAILURE: 'RECONCILE_MULTIPLE_TRANSACTIONS_FAILURE',

  UPDATE_MULTIPLE_TRANSACTIONS_DESCRIPTIONS_REQUEST: 'UPDATE_MULTIPLE_TRANSACTIONS_DESCRIPTIONS_REQUEST',
  UPDATE_MULTIPLE_TRANSACTIONS_DESCRIPTIONS_SUCCESS: 'UPDATE_MULTIPLE_TRANSACTIONS_DESCRIPTIONS_SUCCESS',
  UPDATE_MULTIPLE_TRANSACTIONS_DESCRIPTIONS_FAILURE: 'UPDATE_MULTIPLE_TRANSACTIONS_DESCRIPTIONS_FAILURE',

  UPDATE_MULTIPLE_TRANSACTIONS_TRANSFER_DETAILS_REQUEST: 'UPDATE_MULTIPLE_TRANSACTIONS_TRANSFER_DETAILS_REQUEST',
  UPDATE_MULTIPLE_TRANSACTIONS_TRANSFER_DETAILS_SUCCESS: 'UPDATE_MULTIPLE_TRANSACTIONS_TRANSFER_DETAILS_SUCCESS',
  UPDATE_MULTIPLE_TRANSACTIONS_TRANSFER_DETAILS_FAILURE: 'UPDATE_MULTIPLE_TRANSACTIONS_TRANSFER_DETAILS_FAILURE',

  SET_SELECTED_DATE_REQUEST: 'SET_SELECTED_DATE_REQUEST',

  SET_SELECTED_ACCOUNT_IDS_REQUEST: 'SET_SELECTED_ACCOUNT_IDS_REQUEST',

  // Contacts

  CREATE_CONTACT_REQUEST: 'CREATE_CONTACT_REQUEST',
  CREATE_CONTACT_SUCCESS: 'CREATE_CONTACT_SUCCESS',
  CREATE_CONTACT_FAILURE: 'CREATE_CONTACT_FAILURE',

  UPDATE_CONTACT_REQUEST: 'UPDATE_CONTACT_REQUEST',
  UPDATE_CONTACT_SUCCESS: 'UPDATE_CONTACT_SUCCESS',
  UPDATE_CONTACT_FAILURE: 'UPDATE_CONTACT_FAILURE',

  DELETE_CONTACT_REQUEST: 'DELETE_CONTACT_REQUEST',
  DELETE_CONTACT_SUCCESS: 'DELETE_CONTACT_SUCCESS',
  DELETE_CONTACT_FAILURE: 'DELETE_CONTACT_FAILURE',

  FETCH_CONTACTS_REQUEST: 'FETCH_CONTACTS_REQUEST',
  FETCH_CONTACTS_SUCCESS: 'FETCH_CONTACTS_SUCCESS',
  FETCH_CONTACTS_FAILURE: 'FETCH_CONTACTS_FAILURE',

  FETCH_CONTACT_REQUEST: 'FETCH_CONTACT_REQUEST',
  FETCH_CONTACT_SUCCESS: 'FETCH_CONTACT_SUCCESS',
  FETCH_CONTACT_FAILURE: 'FETCH_CONTACT_FAILURE',

  LOAD_DASHBOARD_DATA_REQUEST: 'LOAD_DASHBOARD_DATA_REQUEST',
  LOAD_DASHBOARD_DATA_SUCCESS: 'LOAD_DASHBOARD_DATA_SUCCESS',
  LOAD_DASHBOARD_DATA_FAILURE: 'LOAD_DASHBOARD_DATA_FAILURE',

  LOAD_TRANSACTIONS_PAGE_DATA_REQUEST: 'LOAD_TRANSACTIONS_PAGE_DATA_REQUEST',
  LOAD_TRANSACTIONS_PAGE_DATA_SUCCESS: 'LOAD_TRANSACTIONS_PAGE_DATA_SUCCESS',
  LOAD_TRANSACTIONS_PAGE_DATA_FAILURE: 'LOAD_TRANSACTIONS_PAGE_DATA_FAILURE',

  UPLOAD_CONTACTS_FROM_INVOICES_REQUEST: 'UPLOAD_CONTACTS_FROM_INVOICES_REQUEST',
  UPLOAD_CONTACTS_FROM_INVOICES_SUCCESS: 'UPLOAD_CONTACTS_FROM_INVOICES_SUCCESS',
  UPLOAD_CONTACTS_FROM_INVOICES_FAILURE: 'UPLOAD_CONTACTS_FROM_INVOICES_FAILURE',

  UPDATE_MULTIPLE_CONTACTS_REQUEST: 'UPDATE_MULTIPLE_CONTACTS_REQUEST',
  UPDATE_MULTIPLE_CONTACTS_SUCCESS: 'UPDATE_MULTIPLE_CONTACTS_SUCCESS',
  UPDATE_MULTIPLE_CONTACTS_FAILURE: 'UPDATE_MULTIPLE_CONTACTS_FAILURE',

  EXPORT_CONTACTS_SPREADSHEET_REQUEST: 'EXPORT_CONTACTS_SPREADSHEET_REQUEST',
  EXPORT_CONTACTS_SPREADSHEET_SUCCESS: 'EXPORT_CONTACTS_SPREADSHEET_SUCCESS',
  EXPORT_CONTACTS_SPREADSHEET_FAILURE: 'EXPORT_CONTACTS_SPREADSHEET_FAILURE',

  // Bank Statement

  FETCH_BANK_STATEMENT_TRANSACTIONS_REQUEST: 'FETCH_BANK_STATEMENT_TRANSACTIONS_REQUEST',
  FETCH_BANK_STATEMENT_TRANSACTIONS_SUCCESS: 'FETCH_BANK_STATEMENT_TRANSACTIONS_SUCCESS',
  FETCH_BANK_STATEMENT_TRANSACTIONS_FAILURE: 'FETCH_BANK_STATEMENT_TRANSACTIONS_FAILURE',

  UPLOAD_BANK_STATEMENT_TRANSACTIONS_REQUEST: 'UPLOAD_BANK_STATEMENT_TRANSACTIONS_REQUEST',
  UPLOAD_BANK_STATEMENT_TRANSACTIONS_SUCCESS: 'UPLOAD_BANK_STATEMENT_TRANSACTIONS_SUCCESS',
  UPLOAD_BANK_STATEMENT_TRANSACTIONS_FAILURE: 'UPLOAD_BANK_STATEMENT_TRANSACTIONS_FAILURE',

  FETCH_BANK_STATEMENT_SUMMARY_REQUEST: 'FETCH_BANK_STATEMENT_SUMMARY_REQUEST',
  FETCH_BANK_STATEMENT_SUMMARY_SUCCESS: 'FETCH_BANK_STATEMENT_SUMMARY_SUCCESS',
  FETCH_BANK_STATEMENT_SUMMARY_FAILURE: 'FETCH_BANK_STATEMENT_SUMMARY_FAILURE',

  CONFIRM_BANK_STATEMENT_UPLOAD_REQUEST: 'CONFIRM_BANK_STATEMENT_UPLOAD_REQUEST',
  CONFIRM_BANK_STATEMENT_UPLOAD_SUCCESS: 'CONFIRM_BANK_STATEMENT_UPLOAD_SUCCESS',
  CONFIRM_BANK_STATEMENT_UPLOAD_FAILURE: 'CONFIRM_BANK_STATEMENT_UPLOAD_FAILURE',

  ABORT_BANK_STATEMENT_UPLOAD_REQUEST: 'ABORT_BANK_STATEMENT_UPLOAD_REQUEST',
  ABORT_BANK_STATEMENT_UPLOAD_SUCCESS: 'ABORT_BANK_STATEMENT_UPLOAD_SUCCESS',
  ABORT_BANK_STATEMENT_UPLOAD_FAILURE: 'ABORT_BANK_STATEMENT_UPLOAD_FAILURE',

  // Recurring Transactions
  CREATE_RECURRING_TRANSACTION_REQUEST: 'CREATE_RECURRING_TRANSACTION_REQUEST',
  CREATE_RECURRING_TRANSACTION_SUCCESS: 'CREATE_RECURRING_TRANSACTION_SUCCESS',
  CREATE_RECURRING_TRANSACTION_FAILURE: 'CREATE_RECURRING_TRANSACTION_FAILURE',

  UPDATE_RECURRING_TRANSACTION_REQUEST: 'UPDATE_RECURRING_TRANSACTION_REQUEST',
  UPDATE_RECURRING_TRANSACTION_SUCCESS: 'UPDATE_RECURRING_TRANSACTION_SUCCESS',
  UPDATE_RECURRING_TRANSACTION_FAILURE: 'UPDATE_RECURRING_TRANSACTION_FAILURE',

  DELETE_RECURRING_TRANSACTION_REQUEST: 'DELETE_RECURRING_TRANSACTION_REQUEST',
  DELETE_RECURRING_TRANSACTION_SUCCESS: 'DELETE_RECURRING_TRANSACTION_SUCCESS',
  DELETE_RECURRING_TRANSACTION_FAILURE: 'DELETE_RECURRING_TRANSACTION_FAILURE',

  FETCH_RECURRING_TRANSACTIONS_REQUEST: 'FETCH_RECURRING_TRANSACTIONS_REQUEST',
  FETCH_RECURRING_TRANSACTIONS_SUCCESS: 'FETCH_RECURRING_TRANSACTIONS_SUCCESS',
  FETCH_RECURRING_TRANSACTIONS_FAILURE: 'FETCH_RECURRING_TRANSACTIONS_FAILURE',

  FETCH_INSTALMENT_TRANSACTIONS_REQUEST: 'FETCH_INSTALMENT_TRANSACTIONS_REQUEST',
  FETCH_INSTALMENT_TRANSACTIONS_SUCCESS: 'FETCH_INSTALMENT_TRANSACTIONS_SUCCESS',
  FETCH_INSTALMENT_TRANSACTIONS_FAILURE: 'FETCH_INSTALMENT_TRANSACTIONS_FAILURE',

  FETCH_TRANSACTIONS_OVERVIEW_REQUEST: 'FETCH_TRANSACTIONS_OVERVIEW_REQUEST',
  FETCH_TRANSACTIONS_OVERVIEW_SUCCESS: 'FETCH_TRANSACTIONS_OVERVIEW_SUCCESS',
  FETCH_TRANSACTIONS_OVERVIEW_FAILURE: 'FETCH_TRANSACTIONS_OVERVIEW_FAILURE',

  // Move Transactions
  MOVE_TRANSACTIONS_REQUEST: 'MOVE_TRANSACTIONS_REQUEST',
  MOVE_TRANSACTIONS_SUCCESS: 'MOVE_TRANSACTIONS_SUCCESS',
  MOVE_TRANSACTIONS_FAILURE: 'MOVE_TRANSACTIONS_FAILURE',

  FETCH_MONTH_COMPARISON_REQUEST: 'FETCH_MONTH_COMPARISON_REQUEST',
  FETCH_MONTH_COMPARISON_SUCCESS: 'FETCH_MONTH_COMPARISON_SUCCESS',
  FETCH_MONTH_COMPARISON_FAILURE: 'FETCH_MONTH_COMPARISON_FAILURE',

  // Download Data
  DOWNLOAD_FINANCIAL_DATA_REQUEST: 'DOWNLOAD_FINANCIAL_DATA_REQUEST',
  DOWNLOAD_FINANCIAL_DATA_SUCCESS: 'DOWNLOAD_FINANCIAL_DATA_SUCCESS',
  DOWNLOAD_FINANCIAL_DATA_FAILURE: 'DOWNLOAD_FINANCIAL_DATA_FAILURE',

  // Transaction Files
  UPLOAD_TRANSACTION_FILES_REQUEST: 'UPLOAD_TRANSACTION_FILES_REQUEST',
  UPLOAD_TRANSACTION_FILES_SUCCESS: 'UPLOAD_TRANSACTION_FILES_SUCCESS',
  UPLOAD_TRANSACTION_FILES_FAILURE: 'UPLOAD_TRANSACTION_FILES_FAILURE',

  FETCH_COMPANY_TRANSACTION_FILES_REQUEST: 'FETCH_COMPANY_TRANSACTION_FILES_REQUEST',
  FETCH_COMPANY_TRANSACTION_FILES_SUCCESS: 'FETCH_COMPANY_TRANSACTION_FILES_SUCCESS',
  FETCH_COMPANY_TRANSACTION_FILES_FAILURE: 'FETCH_COMPANY_TRANSACTION_FILES_FAILURE',

  DELETE_TRANSACTION_FILES_REQUEST: 'DELETE_TRANSACTION_FILES_REQUEST',
  DELETE_TRANSACTION_FILES_SUCCESS: 'DELETE_TRANSACTION_FILES_SUCCESS',
  DELETE_TRANSACTION_FILES_FAILURE: 'DELETE_TRANSACTION_FILES_FAILURE',

  UPLOAD_CONTACTS_SPREADSHEET_REQUEST: 'UPLOAD_CONTACTS_SPREADSHEET_REQUEST',
  UPLOAD_CONTACTS_SPREADSHEET_SUCCESS: 'UPLOAD_CONTACTS_SPREADSHEET_SUCCESS',
  UPLOAD_CONTACTS_SPREADSHEET_FAILURE: 'UPLOAD_CONTACTS_SPREADSHEET_FAILURE',

  UPLOAD_CONTACTS_SPREADSHEET_CONFIRM_REQUEST: 'UPLOAD_CONTACTS_SPREADSHEET_CONFIRM_REQUEST',
  UPLOAD_CONTACTS_SPREADSHEET_CONFIRM_SUCCESS: 'UPLOAD_CONTACTS_SPREADSHEET_CONFIRM_SUCCESS',
  UPLOAD_CONTACTS_SPREADSHEET_CONFIRM_FAILURE: 'UPLOAD_CONTACTS_SPREADSHEET_CONFIRM_FAILURE',

  SORT_TRANSACTIONS: 'SORT_TRANSACTIONS',

  // Search
  SEARCH_REQUEST: 'SEARCH_REQUEST',
  SEARCH_SUCCESS: 'SEARCH_SUCCESS',
  SEARCH_FAILURE: 'SEARCH_FAILURE',
  CLEAR_SEARCH_RESULTS: 'CLEAR_SEARCH_RESULTS',

  CLEAR_TRANSACTION_FILES: 'CLEAR_TRANSACTION_FILES',

  GET_CONSUMED_STORAGE_REQUEST: 'GET_CONSUMED_STORAGE_REQUEST',
  GET_CONSUMED_STORAGE_SUCCESS: 'GET_CONSUMED_STORAGE_SUCCESS',
  GET_CONSUMED_STORAGE_FAILURE: 'GET_CONSUMED_STORAGE_FAILURE',

  // Expired Transactions
  FETCH_ALL_EXPIRED_COMPANY_TRANSACTIONS_REQUEST: 'FETCH_ALL_EXPIRED_COMPANY_TRANSACTIONS_REQUEST',
  FETCH_ALL_EXPIRED_COMPANY_TRANSACTIONS_SUCCESS: 'FETCH_ALL_EXPIRED_COMPANY_TRANSACTIONS_SUCCESS',
  FETCH_ALL_EXPIRED_COMPANY_TRANSACTIONS_FAILURE: 'FETCH_ALL_EXPIRED_COMPANY_TRANSACTIONS_FAILURE',

  UPLOAD_TRANSACTIONS_SPREADSHEET_REQUEST: 'UPLOAD_TRANSACTIONS_SPREADSHEET_REQUEST',
  UPLOAD_TRANSACTIONS_SPREADSHEET_SUCCESS: 'UPLOAD_TRANSACTIONS_SPREADSHEET_SUCCESS',
  UPLOAD_TRANSACTIONS_SPREADSHEET_FAILURE: 'UPLOAD_TRANSACTIONS_SPREADSHEET_FAILURE',

  UPLOAD_TRANSACTIONS_SPREADSHEET_CONFIRM_REQUEST: 'UPLOAD_TRANSACTIONS_SPREADSHEET_CONFIRM_REQUEST',
  UPLOAD_TRANSACTIONS_SPREADSHEET_CONFIRM_SUCCESS: 'UPLOAD_TRANSACTIONS_SPREADSHEET_CONFIRM_SUCCESS',
  UPLOAD_TRANSACTIONS_SPREADSHEET_CONFIRM_FAILURE: 'UPLOAD_TRANSACTIONS_SPREADSHEET_CONFIRM_FAILURE',

  CLEAR_DUPLICATED_TRANSACTIONS_REQUEST: 'CLEAR_DUPLICATED_TRANSACTIONS_REQUEST',

  CREATE_EMPTY_TRANSACTION_REQUEST: 'CREATE_EMPTY_TRANSACTION_REQUEST',
  CREATE_EMPTY_TRANSACTION_SUCCESS: 'CREATE_EMPTY_TRANSACTION_SUCCESS',
  CREATE_EMPTY_TRANSACTION_FAILURE: 'CREATE_EMPTY_TRANSACTION_FAILURE',

  // Split Transactions
  CREATE_SPLIT_TRANSACTION_REQUEST: 'CREATE_SPLIT_TRANSACTION_REQUEST',
  CREATE_SPLIT_TRANSACTION_SUCCESS: 'CREATE_SPLIT_TRANSACTION_SUCCESS',
  CREATE_SPLIT_TRANSACTION_FAILURE: 'CREATE_SPLIT_TRANSACTION_FAILURE',

  UPDATE_SPLIT_TRANSACTION_REQUEST: 'UPDATE_SPLIT_TRANSACTION_REQUEST',
  UPDATE_SPLIT_TRANSACTION_SUCCESS: 'UPDATE_SPLIT_TRANSACTION_SUCCESS',
  UPDATE_SPLIT_TRANSACTION_FAILURE: 'UPDATE_SPLIT_TRANSACTION_FAILURE',

  DELETE_SPLIT_TRANSACTION_REQUEST: 'DELETE_SPLIT_TRANSACTION_REQUEST',
  DELETE_SPLIT_TRANSACTION_SUCCESS: 'DELETE_SPLIT_TRANSACTION_SUCCESS',
  DELETE_SPLIT_TRANSACTION_FAILURE: 'DELETE_SPLIT_TRANSACTION_FAILURE',

  FETCH_SPLIT_TRANSACTIONS_REQUEST: 'FETCH_SPLIT_TRANSACTIONS_REQUEST',
  FETCH_SPLIT_TRANSACTIONS_SUCCESS: 'FETCH_SPLIT_TRANSACTIONS_SUCCESS',
  FETCH_SPLIT_TRANSACTIONS_FAILURE: 'FETCH_SPLIT_TRANSACTIONS_FAILURE',

  CLEAR_TRANSACTIONS_REQUEST: 'CLEAR_TRANSACTIONS_REQUEST',

  ARCHIVE_BANK_ACCOUNT_REQUEST: 'ARCHIVE_BANK_ACCOUNT_REQUEST',
  ARCHIVE_BANK_ACCOUNT_SUCCESS: 'ARCHIVE_BANK_ACCOUNT_SUCCESS',
  ARCHIVE_BANK_ACCOUNT_FAILURE: 'ARCHIVE_BANK_ACCOUNT_FAILURE',

  UNARCHIVE_BANK_ACCOUNT_REQUEST: 'UNARCHIVE_BANK_ACCOUNT_REQUEST',
  UNARCHIVE_BANK_ACCOUNT_SUCCESS: 'UNARCHIVE_BANK_ACCOUNT_SUCCESS',
  UNARCHIVE_BANK_ACCOUNT_FAILURE: 'UNARCHIVE_BANK_ACCOUNT_FAILURE',

  UPLOAD_PAYMENTS_FILE_REQUEST: 'UPLOAD_PAYMENTS_FILE_REQUEST',
  UPLOAD_PAYMENTS_FILE_SUCCESS: 'UPLOAD_PAYMENTS_FILE_SUCCESS',
  UPLOAD_PAYMENTS_FILE_FAILURE: 'UPLOAD_PAYMENTS_FILE_FAILURE',

  UPLOAD_PAYMENTS_FILE_CONFIRM_REQUEST: 'UPLOAD_PAYMENTS_FILE_CONFIRM_REQUEST',
  UPLOAD_PAYMENTS_FILE_CONFIRM_SUCCESS: 'UPLOAD_PAYMENTS_FILE_CONFIRM_SUCCESS',
  UPLOAD_PAYMENTS_FILE_CONFIRM_FAILURE: 'UPLOAD_PAYMENTS_FILE_CONFIRM_FAILURE',
};

export default companyTransactionsConstants;
